<script lang="jsx">
import { sui_icon_more_down_12px_2, sui_icon_more_up_12px_2 } from '@shein-aidc/icon-vue2'
export default {
  name: 'NavBarSort',
  functional: true,
  components: {
    sui_icon_more_down_12px_2,
    sui_icon_more_up_12px_2,
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    // 样式集合
    styles: {
      type: Object,
      default: () => ({
        navItem: {},
        navBar: {}
      })
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      const item = this.item
      if (item.disable) return
      // 激活高亮和icon
      this.$set(item, 'active', !item.active)
      this.$emit('click', { ...item })
    },
  },
  render(_, { props, listeners }) {
    const { item = {}, styles = { navItem: {}, navBar: {} }, active } = props || {}
    const navBarItemClassList = [
      'nav-bar__item',
      {
        'nav-bar__item_seperator': item.hasSeperator,
        'nav-bar__item_disable': item.disable
      },
    ]
    const navBarItemTextClassList = [
      'nav-bar__item_text',
      {
        'nav-bar__item_highlight': (item.hasCountBadge ? item.panelContentSelected : (item.panelContentSelected || active)),
        'nav-bar__item_gap': item.icons
      }
    ]
    const icon = active ? 
      < sui_icon_more_up_12px_2 
        size="12px"
        class="nav-bar__item_icon"
      />  
      :  < sui_icon_more_down_12px_2 
        size="12px"
        class="nav-bar__item_icon"
      /> 
    return (
      <div
        class={navBarItemClassList}
        style={styles}
        tabindex='0'
        role='tab'
        vOn:click={function handleClickNavBarSort() {
          if (item.disable) return
          listeners['click']({ ...item })
        }}
      >
        <span
          class={navBarItemTextClassList}
          style={item.styles}
        >
          { item.label || item.label_en || item.text }

          {
            // icon配置
            item.icons ? (icon) : null
          }
        </span>
      </div>
    )
  }
}
</script>

<style lang="less">
.nav-bar__item_text {
  .nav-bar__item_icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(3px);
    color: #222;
    font-weight: 400;
  }
}
</style>
