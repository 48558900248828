<template>
  <div
    ref="navBar"
    class="filter-ctn__nav-bar"
    :style="styles.navBar"
    :class="{
      'nav-bar_border-none': !bottomBorder
    }"
  >
    <!-- 排序外露 -->
    <div
      v-if="navBarConfig.type === NAVBAR_EXPORT"
      class="nav-bar__item nav-bar__export"
    >
      <swiper-container 
        ref="sortSwiper"
        class="nav-bar__export_left"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide class="nav-bar__export_left-sort">
          <NavBarSort
            :item="exportSearchSortItem"
            :styles="styles.navItem"
            :active="navBarSortActive"
            @click="handleClickNavBarSort"
          />
        </swiper-slide>

        <swiper-slide
          :class="{
            'nav-bar__export_abt': true,
            'nav-bar__export_abt-center': exportSearchPopularItem || exportSearchPriceItem,
            'nav-bar__export_abt-left': exportSearchPopularItem && exportSearchPriceItem,
          }"
        >
          <NavBarPopular
            v-if="exportSearchPopularItem"
            :item="exportSearchPopularItem"
            :styles="styles.navItem"
            @click="(payload) => $emit('clickSort', payload)"
          />

          <NavBarPrice
            v-if="exportSearchPriceItem"
            :item="exportSearchPriceItem"
            :styles="styles.navItem"
            @click="(payload) => $emit('clickSort', payload)"
          />
        </swiper-slide>
      </swiper-container>
      <div class="nav-bar__export_side">
        <NavBarSide
          :item="exportSearchSideItem"
          :style="styles.navItem"
          :selected-num="selectedNum"
          @click="(payload) => clickExportBar(payload, 1)"
        />
      </div>
    </div>

    <!-- 快速筛选 -->
    <div
      v-else-if="navBarConfig.type === NAVBAR_QUICK && quickSearchNavConfigCopy && quickSearchNavConfigCopy.length"
      class="nav-bar__item nav-bar__quick-search"
    >
      <swiper-container 
        ref="sortSwiper"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="(item, index) in quickSearchNavConfigCopy"
          :key="index"
          :class="{
            'nav-bar__item_highlight' : item.active
          }"
          @click="clickQuickSearchBar(index, item)"
        >
          <span>{{ item.text }}</span>
          <i
            v-if="item.icon"
            :class="[
              'iconfont',
              item.icon ? item.icon : '']"
          ></i>
        </swiper-slide>
      </swiper-container>
    </div>

    <!-- 面板筛选 -->
    <template v-else>
      <div
        v-for="(item, index) in panelSearchNavConfigCopy"
        v-show="item.isShow"
        :key="index"
        :ref="`navBar${index}`"
        :class="[
          'nav-bar__item', 
          'nav-bar__relate-panel',
          {
            'nav-bar__item_seperator' : item.hasSeperator,
            'nav-bar__item_disable': item.disable,
            'nav-bar__category-view': viewType == 'category',
          },
        ]"
        :style="styles.navItem"
        tabindex="0"
        :aria-label="item.text"
        role="tab"
        @click="clickBar(item, index)"
        @focus="$emit('navFocus', $event)"
      >
        <span
          class="nav-bar__item_text"
          :class="{
            'nav-bar__item_highlight' : (item.hasCountBadge ? selectedNum : (item.panelContentSelected || item.active)),
            'nav-bar__item_gap': item.icons
          }"
          :style="item.styles"
        >
          {{ item.text }}

          <!-- icon配置 -->
          <template v-if="item.icons">
            <sui_icon_filter_16px
              v-if="item.hasSeperator"
              class="nav-bar__item-icon"
              size="16px"
            />
            <sui_icon_more_up_12px_2
              v-if="!item.hasSeperator"
              v-show="item.active"
              size="12px"
              class="nav-bar__item-icon"
            />
            <sui_icon_more_down_12px_2
              v-if="!item.hasSeperator"
              v-show="!item.active"
              size="12px"
              class="nav-bar__item-icon"
            />
            <span
              v-if="item.hasCountBadge && selectedNum"
              class="nav-bar__item_badge"
            >
              {{ selectedNum }}
            </span>
          </template>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import NavBarSort from './NavBarSort'
import NavBarPopular from './NavBarPopular'
import NavBarPrice from './NavBarPrice'
import NavBarSide from './NavBarSide'
import { NAVBAR_EXPORT, NAVBAR_PANEL, NAVBAR_QUICK } from '../utils/constants'
import { runDelay } from '../../../../js/utils'
import { register } from 'swiper/element'
import { sui_icon_more_down_12px_2, sui_icon_more_up_12px_2, sui_icon_filter_16px } from '@shein-aidc/icon-vue2'
typeof window !== 'undefined' && register()

export default {
  name: 'NavBar',
  components: {
    NavBarSort,
    NavBarPopular,
    NavBarPrice,
    sui_icon_more_down_12px_2,
    sui_icon_more_up_12px_2,
    sui_icon_filter_16px,
    NavBarSide
  },
  props: {
    reload: Boolean,
    // 头部导航配置
    navConfig: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => ({})
    },
    navBarConfig: {
      type: Object,
      default: () => ({})
    },
    // 选中的筛选数量
    selectedNum: {
      type: Number,
      default: 0
    },
    // 样式集合
    styles: {
      type: Object,
      default: () => ({
        navItem: {},
        navBar: {}
      })
    },
    bottomBorder: {
      type: Boolean,
      default: true
    },
    viewType: {
      type: String,
      default: ''
    }
  },
  data () {
    // 非响应式数据
    Object.assign(this, {
      NAVBAR_EXPORT,
      NAVBAR_PANEL,
      NAVBAR_QUICK,
      savedIndex: []
    })
    return {
      // disableIndex: '',
      panelSearchNavConfigCopy: [],
      quickSearchNavConfigCopy: [],
      exportSearchNavConfigCopy: [],
      // savedIndex: [],
      // NAVBAR_EXPORT, NAVBAR_PANEL, NAVBAR_QUICK,
      navBarSortActive: false,
    }
  },
  computed: {
    exportSearchSortItem() {
      return this.navConfig[0]
    },
    exportSearchPopularItem() {
      if (this.navBarConfig.isShowMostPopularBar) {
        return this.navConfig[1]
      }

      return null
    },
    exportSearchPriceItem() {
      if (this.navBarConfig.isShowPriceBar) {
        return this.navConfig.find(item => item.filterType === 'price')
      }

      return null
    },
    exportSearchSideItem() {
      return this.navConfig[this.navConfig.length - 1]
    },
  },
  watch: {
    navConfig: {
      handler (val, oldVal) {
        if (val === oldVal) return
        
        if (this.reload) {
          this.panelSearchNavConfigCopy.forEach((item, index) => {
            if (!this.savedIndex.includes(index)) {
              delete item.panelContentSelected
            }
          })
          this.$emit('update:reload', false)
        }
        let panelActive = null
        let selectedBars = this.panelSearchNavConfigCopy.map(item => {
          if (item.active) {
            panelActive = item.text
          }
          return item.panelContentSelected ? item.text : ''
        })
        // 重新注入panelContentSelected和active
        this.navConfig.forEach(item => {
          if (selectedBars.includes(item.text)) {
            item.panelContentSelected = true // 高亮标识
          }
          if (panelActive === item.text) {
            item.active = true // 高亮和箭头激活标识
          }
        })

        this.handleNavConfig()
        this.$nextTick(() => runDelay(this.initSwiper))
        this.navBarSortActive = false
      },
      immediate: true
    },
    viewType: {
      handler(val, oldVal) {
        if (val === oldVal) return
        this.panelSearchNavConfigCopy.forEach((item, idx) => {
          item.isShow = true
          if (val === 'category' && item.text === this.language.SHEIN_KEY_PWA_14980) {
            item.isShow = false
          }
          // 切换视图的时候关闭下拉选项弹窗
          if (item.active) {
            this.clickBar(item, idx)
          }
        })
      },
      immediate: false
    }
  },
  beforeDestroy () { 
    this.$refs.sortSwiper?.swiper?.destroy?.()
  },
  mounted() {
    window.$navBar = this
  },
  methods: {
    reactivePanel (activeIndex) {
      this.$emit('reactivePanel', activeIndex)
    },
    handleNavConfig () {
      if (this.navBarConfig.type === NAVBAR_EXPORT) {
        this.exportSearchNavConfigCopy = cloneDeep(this.navConfig)
        return
      }

      const quickSearchNavConfig = []
      const panelSearchNavConfig = []
      this.navConfig.forEach(item => {
        if (item.type == 'QuickSearch') {
          quickSearchNavConfig.push(item)
        } else {
          item.isShow = this.viewType === 'category' && item.text === this.language.SHEIN_KEY_PWA_14980 ? false : true
          panelSearchNavConfig.push(item)
        }
      })

      this.quickSearchNavConfigCopy = cloneDeep(quickSearchNavConfig)
      this.panelSearchNavConfigCopy = cloneDeep(panelSearchNavConfig)
    },
    initSwiper () {
      if (typeof window !== 'undefined' 
      && this.$refs.sortSwiper
      && (
        this.navBarConfig.isExportFilter
         || this.quickSearchNavConfigCopy.length
      )
      ) {
        
        const swiperEl = this.$refs.sortSwiper

        if (swiperEl?.swiper?.update) {
          swiperEl.swiper.update()
          return
        }
        
        const swiperParams = {
          slidesPerView: 'auto',
          freeMode: true,
          ...(this.quickSearchNavConfigCopy.length ? {} : {
            slideToClickedSlide: true,
            setWrapperSize: true,
          })
        }
        Object.assign(swiperEl, swiperParams)
        swiperEl.initialize()
      }
    },
    clickExportBar(item, index) {
      if (item.disable) return
      this.navBarSortActive = false
      this.$emit('onChange', { ...item, activeIndex: index })
    },
    clickBar (item, activeIndex) {
      if (item.disable) return
      // 激活高亮和icon
      this.panelSearchNavConfigCopy.forEach((item, idx) => {
        if (activeIndex != idx) {
          this.$set(item, 'active', false)
        }
      })
      const activeStatus = !this.panelSearchNavConfigCopy[activeIndex].active
      this.$set(this.panelSearchNavConfigCopy[activeIndex], 'active', activeStatus)
      // 
      if (activeStatus) {
        const target = document.body.offsetHeight - this.$refs.navBar?.getBoundingClientRect().bottom
        if (target < 240) {
          window.scrollTo(0, window.scrollY - target + 250)
        }
      }
      this.$emit('onChange', { ...item, activeIndex })
    },
    resetArrow () {
      this.exportSearchSortItem && this.$set(this.exportSearchSortItem, 'active', false)

      this.panelSearchNavConfigCopy.forEach(item => {
        this.$set(item, 'active', false)
      })
    },
    clickQuickSearchBar (activeIndex, item) {
      this.quickSearchNavConfigCopy.forEach(item => {
        this.$set(item, 'active', false)
      })

      this.$set(this.quickSearchNavConfigCopy[activeIndex], 'active', true)

      this.$emit('quickSearch', item)
    },
    /**
     * panel中内容被选中
     * curIndex 当前操作bar的角标
     * curBarHighlight 当前操作的bar
     * reset 重置
     * */
    controlHighlight ({ curIndex, curBarHighlight, reset }) {
      if (!this.panelSearchNavConfigCopy.length) return

      if (reset) {
        this.panelSearchNavConfigCopy.forEach((item, idx) => {
          if (!this.savedIndex.includes(idx)) {
            this.$set(item, 'panelContentSelected', false)
          }
        })
        return
      }

      const indexArr = []
      if (curIndex || curIndex === 0) {
        indexArr.push({ index: curIndex, highlight: curBarHighlight })
      }

      indexArr.forEach(item => {
        this.$set(this.panelSearchNavConfigCopy[item.index], 'panelContentSelected', item.highlight)
      })
    },
    saveIndex (index) {
      this.savedIndex.push(index)
    },
    handleClickNavBarSort(payload) {
      const { navBarConfig: { isExportFilterSort = false }, navBarSortActive } = this
      if (
        // 未命中exportFilter&sort实验 每次点击都展开下拉选项
        !isExportFilterSort
        ||
        // 命中实验但未选则navSortBar选项
        (
          isExportFilterSort
          && 
          payload.panelContentSelected
        )
      ) this.navBarSortActive = !navBarSortActive
      this.$emit('onChange', {
        ...payload,
        activeIndex: 0
      })
    }
  }
}
</script>

<style lang="less">
.filter-ctn {
  &__nav-bar {
    position: relative;
    z-index: @zindex-list-hdfilter;
    .flexbox();
    .align-center();
    .space-between();
    width: 100%;
    height: 1.12rem;
    background-color: #fff;
    .font-dpr(28px);
    color: #666;
    .border-dpr(border-bottom, 1px, #e5e5e5);

    .nav-bar__export-left {
      .flexbox();
      .align-center();
      
      .nav-bar__export-abt{
        flex: 1;
        .flexbox();
        .align-center();
        .space-between();
      }
    }
    
    .nav-bar {
      &__relate-panel {
        &:first-of-type,
        &:last-of-type {
          flex: 1 0 auto;
        }
      }
      &__category-view {
        &:first-of-type,
        &:last-of-type {
          flex: initial;
        }
      }
      &__item {
        position: relative;
        padding: 0 .32rem;
        flex: 1 1 auto;
        .flexbox();
        .pack-center();
        .align-center();
        .text-overflow();
        &_gap {
          padding-right: 14px;
        }
        &_text {
          position: relative;
          height: 100%;
          .text-overflow();
          /* rw:begin */
          font-family: 'SFUIText';
          [class*="iconfont"] {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: @sui_color_gray_dark2;
            font-weight: normal;
            height: 100%;
            .flexbox();
            .align-center();
          }
          .iconfont_rotate {
            transform: rotate(180deg);
          }
        }
        &_seperator {
          .border-dpr(border-left, 1px, #e5e5e5);
        }
        &_disable {
          opacity: .5;
        }
        &_badge {
          position: absolute;
          top: 0;
          right: 0;
          width: 13px;
          height: 13px;
          line-height: 13px;
          text-align: center;
          border-radius: 50%;
          background-color: #ccc;
          color: #fff;
          font-size: 12px;
          z-index: @zindex-hack;
          font-weight: normal;
        }
        &_highlight {
          font-weight: bold;
          color: @sui_color_gray_dark1;

          [class*="iconfont"] {
            color: @sui_color_gray_dark1;
          }
        }

        swiper-container {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          align-items: center;
          position: relative;
          overflow: hidden;
        }

        swiper-slide {
          width: auto;
          flex-shrink: 0;
        }

      }
      /* stylelint-disable selector-max-specificity, selector-class-pattern */
      &__quick-search {
        flex: 1 1 auto;
        .font-dpr(24px);
        .iconfont {
          position: relative;
          &.icon-price_px_ {
            top: -4px;
          }
          &.icon-price_px_- {
            top: 4px;
          }
        }

        swiper-container {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          min-height: 18 / 37.5rem;
          align-items: center;
          position: relative;
          overflow: hidden;
        }
        swiper-slide {
          margin-right: 0.2rem;
        }
        swiper-slide:last-child {
          margin-right: 0;
        }

      }
      &__export {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0;

        &_left {
          flex: 1;
          .flexbox();
          position: relative;

          &::after {
            content: "";
            position: absolute;
            right: 0;
            width: 0.5334rem;
            height: 100%;
            background: linear-gradient(to left, #fff 0, #fff 0.32rem, rgba(255, 255, 255, 0) 0.5334rem);
            z-index: 1;
          }
        }
        &_left-sort {
          .nav-bar__item {
            max-width: 3.5734rem;
          }            
        }

        &_abt{
          flex: 1;
          .flexbox();
          .align-center();

          .nav-bar__item {
            cursor: pointer;
            flex: none;
            padding-left: 0;
            padding-right: 0.426rem;
            max-width: 3.36rem;
            &.nav-bar__item-price {
              padding-right: 0;
            }
          }
        }

        &_abt-center{
          justify-content: center;
        }

        &_abt-left{
          justify-content: space-evenly;
        }
      }
    }
    &.nav-bar_border-none {
      border-bottom: none;
    }
  }
}
.nav-bar__item_text {
  .nav-bar__item-icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(3px);
    color: #666;
    font-weight: 400;
  }
}
</style>
